@tailwind base;
@tailwind components;
@tailwind utilities;

/* @font-face {
  font-family: var(--font-madelyn-2), var(--font-madelyn-1);
  font-display: swap;
  /* src: url('/assets/fonts/madelyn-regular-font.woff2') format('woff2'),
    url('/assets/fonts/madelyn-regular-font.woff') format('woff'); */
/* } */

/* @font-face {
  font-family: var(--font-tucker-tub), var(--font-tucker-tub-2);
  font-display: swap;
  /* src: url("/assets/fonts/TuckerTub.woff2") format("woff2"),
    url("/assets/fonts/TuckerTub.woff") format("woff"); */
/* } */

html {
  overflow: auto !important;
}

.font-madelyn {
  font-family: var(--font-madelyn-2), var(--font-madelyn-1);
}
.font-tucker-tub {
  font-family: var(--font-tucker-tub), var(--font-tucker-tub-2);
}

/* https://tailwindcss.com/docs/adding-base-styles */

:root {
  --footer-height: 10vh;
  --navbar-height: 10vh;
  --announcement-bar-height: 40px;
  --mobile-navbar-height: 60px;
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer base {
  h1 {
    @apply py-6 text-3xl font-bold;
  }
  h2 {
    @apply py-4 text-2xl font-bold;
  }
  h3 {
    @apply py-4 text-xl font-bold;
  }
  h4 {
    @apply py-4 text-lg font-bold;
  }
}

@layer components {
  .container {
    @apply max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl p-4 md:p-6 lg:p-8 xl:p-10 m-auto;
  }

  a.primary-link {
    @apply text-lg;
  }

  input.primary-input,
  textarea.primary-input,
  select.primary-input {
    @apply py-2 px-4 w-full text-md text-colorFourteen rounded-lg border-[3px] border-transparent transition duration-500 ease-in-out;
  }

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    @apply text-colorFourteen;
  }

  input::-moz-placeholder,
  textarea::-moz-placeholder {
    @apply text-colorFourteen;
  }

  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    @apply text-colorFourteen;
  }

  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    @apply text-colorFourteen;
  }

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    @apply text-colorFourteen;
  }

  input::-moz-placeholder,
  textarea::-moz-placeholder {
    @apply text-colorFourteen;
  }

  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    @apply text-colorFourteen;
  }

  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    @apply text-colorFourteen;
  }

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    @apply text-colorFourteen;
  }

  input::-moz-placeholder,
  textarea::-moz-placeholder {
    @apply text-colorFourteen;
  }

  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    @apply text-colorFourteen;
  }

  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    @apply text-colorFourteen;
  }

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    @apply text-colorFourteen;
  }

  input::-moz-placeholder,
  textarea::-moz-placeholder {
    @apply text-colorFourteen;
  }

  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    @apply text-colorFourteen;
  }

  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    @apply text-colorFourteen;
  }

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    @apply text-colorFourteen;
  }

  input::-moz-placeholder,
  textarea::-moz-placeholder {
    @apply text-colorFourteen;
  }

  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    @apply text-colorFourteen;
  }

  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    @apply text-colorFourteen;
  }

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    @apply text-colorFourteen;
  }

  input::-moz-placeholder,
  textarea::-moz-placeholder {
    @apply text-colorFourteen;
  }

  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    @apply text-colorFourteen;
  }

  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    @apply text-colorFourteen;
  }

  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    @apply text-colorFourteen;
  }

  input::-moz-placeholder,
  textarea::-moz-placeholder {
    @apply text-colorFourteen;
  }

  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    @apply text-colorFourteen;
  }

  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    @apply text-colorFourteen;
  }

  input::placeholder,
  textarea::placeholder {
    @apply text-colorFourteen;
  }

  input.primary-input-isValid,
  textarea.primary-input-isValid,
  select.primary-input-isValid {
    @apply !border-colorTen;
  }

  input.primary-input-isInvalid,
  textarea.primary-input-isInvalid,
  select.primary-input-isInvalid {
    @apply !border-colorThirteen;
  }

  input[type="text"]:focus,
  textarea:focus {
    box-shadow: none;
  }

  button:disabled {
    @apply !bg-colorFive;
  }

  .preflight-escape ol {
    display: block;
    list-style-type: decimal;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }

  .preflight-escape ul {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 40px;
  }

  .preflight-escape p {
    @apply py-2;
  }
}

/* Apply basic global styles to the body: */
body {
  @apply antialiased break-words font-haboro-soft text-mdlg text-black bg-white dark:bg-black dark:text-white;
}

/* Remove trailing margin from forms for some browsers: */
form {
  -webkit-margin-after: 0;
  margin-block-end: 0;
}

/* NOTE: Webkit autofill can be very annoying, and leave the auto-filled content invisible to the user. */
/* We try to avoid this by setting this to a sane default, which seems to work based on some experimentation. */
input:-webkit-autofill {
  -webkit-text-fill-color: theme("colors.gray.800") !important;
}

/* This correctly styles the minimal progress bar that is displayed when navigating between pages */
#nprogress {
  @apply z-50 pointer-events-none;
}

#nprogress .bar {
  @apply bg-[slateblue] fixed z-50 top-0 left-0 w-full h-0.5;
}

/* Navbar */

span.hamburger-span {
  position: absolute;
  height: 0.48rem;
  width: 3.3rem;
  left: 50%;
  border-radius: 0.3rem;
  transition: all 0.25s ease-in-out, background-color 0s ease-in-out;
  @apply bg-black dark:bg-white;
}

span.middle-span {
  @apply top-2/4 -translate-x-2/4 -translate-y-2/4;
}

/* Modal */

#modal-container ul li {
  padding: 1rem 0;
}

#modal-container ul li a {
  color: inherit;
  text-decoration: none;
  transition: color 0.4s ease;
}

#modal-container ul li a:hover {
  color: #62ff62;
}

/* Cart */

#cart-component-link:focus,
.mega-menu-link:focus {
  outline: none;
}

/* #cart-component-link:focus-visible,
.mega-menu-link:focus-visible {
  outline: 2px solid blue;
} */

/* Cart Modal Overlay */

.ReactModal_cart_overlay::after {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  content: "";
  width: 100vw;
  height: 100vh;
  z-index: 1401;
  background-color: rgba(0, 0, 0, 0.45);
}

/* Google Maps */

.map-container {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#google-map-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.curve {
  -webkit-clip-path: url(#clip);
}

@media (max-width: 480px) {
  .sliderimg {
    background-position: 0px center;
  }
  .sliderhome {
    background-position: -300px center;
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  .sliderimg {
    background-position: 100px center;
  }
  .sliderhome {
    background-position: 0px center;
  }
}

@media (min-width: 1025px) {
  .sliderimg {
    background-position: 400px center;
  }
  .sliderhome {
    background-position: 200px center;
  }
}

.stepper-one {
  -webkit-clip-path: polygon(85% 0%, 100% 50%, 85% 100%, 0% 100%, 0 52%, 0% 0%);
  clip-path: polygon(85% 0%, 100% 50%, 85% 100%, 0% 100%, 0 52%, 0% 0%);
}

.stepper-two {
  -webkit-clip-path: polygon(
    85% 0%,
    100% 50%,
    85% 100%,
    0% 100%,
    15% 50%,
    0% 0%
  );
  clip-path: polygon(85% 0%, 100% 50%, 85% 100%, 0% 100%, 15% 50%, 0% 0%);
}

.stepper-three {
  -webkit-clip-path: polygon(
    100% 0,
    100% 55%,
    100% 100%,
    0% 100%,
    15% 50%,
    0% 0%
  );
  clip-path: polygon(100% 0, 100% 55%, 100% 100%, 0% 100%, 15% 50%, 0% 0%);
}

/* Embla Carousel */
.embla {
  overflow: hidden;
}

.embla__container {
  /* height: calc(100vh - var(--navbar-height) - var(--announcement-bar-height)); */
  height: 100%;
  display: grid;
  grid-auto-flow: column;
}

.embla__slide {
  position: relative;
}

/* Custom Embla */

.embla__viewport.is-draggable {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.embla__viewport.is-dragging {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.embla__dots {
  display: flex;
  list-style: none;
  justify-content: center;
  position: absolute;
  left: 50%;
  bottom: 2.5%;
  transform: translate(-50%, -50%);
}

.embla__dot {
  background-color: transparent;
  cursor: pointer;
  position: relative;
  padding: 0;
  outline: 0;
  border: 0;
  width: 16px;
  height: 16px;
  margin-right: 7.5px;
  margin-left: 7.5px;
  display: flex;
  align-items: center;
}

.embla__dot:after {
  background-color: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 100%;
  border-radius: 100%;
  content: "";
}

.embla__dot.is-selected:after {
  background-color: rgba(255, 255, 255, 1);
  opacity: 1;
}

/* Custom Embla Fade for Hero Slider */

.embla__slide__hero {
  position: relative;
  opacity: 0.8;
  transition: opacity 0.8s;
}

.embla__slide__hero.is-active-slide {
  opacity: 1;
  z-index: 1;
}

/* Custom Embla Product Page */

.product-page-embla {
  position: relative;
  background-color: #f7f7f7;
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
}

.product-page-embla__viewport {
  overflow: hidden;
  width: 100%;
}

.product-page-embla__viewport.is-draggable {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.product-page-embla__viewport.is-dragging {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.product-page-embla__container {
  display: flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.product-page-embla__slide {
  padding: 0 10px;
  min-width: 100%;
  position: relative;
}

.product-page-embla__slide__inner {
  position: relative;
  overflow: hidden;
  height: 560px;
}

.product-page-embla__slide__img {
  position: absolute;
  display: block;
  width: auto;
  min-height: 100%;
  min-width: 100%;
  max-width: none;
  border-radius: 8px;
}

.product-page-embla--thumb {
  padding: 8px;
  margin-top: 2px;
  max-height: 170px;
}

.product-page-embla__container--thumb {
  cursor: default;
  margin-left: -8px;
}

.product-page-embla__slide--thumb {
  padding-left: 8px;
  min-width: 190px;
}

@media screen and (max-width: 600px) {
  .product-page-embla__slide--thumb {
    min-width: 110px;
  }

  .product-page-embla--thumb {
    height: 120px;
  }

  .product-page-embla__slide__inner {
    width: 100px;
  }
  .product-page-embla__slide__inner--thumb {
    height: 100px;
  }
}

.product-page-embla__slide__inner--thumb {
  touch-action: manipulation;
  border-radius: 8px;
  cursor: pointer;
  border: 0;
  outline: 0;
  margin: 0;
  padding: 0;
  max-height: 173px;
  aspect-ratio: 1/1;
  position: relative;
  display: block;
  overflow: hidden;
}

.product-page-embla__slide__thumbnail {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -10000%;
  right: -10000%;
  margin: auto;
  min-width: 1000%;
  min-height: 1000%;
  max-width: none;
  transform: scale(0.1);
}

.product-page-embla__slide--thumb.is-selected .embla__slide__thumbnail {
  opacity: 1;
}

.out-of-stock-variant {
  position: relative;
}

.out-of-stock-variant:after {
  position: absolute;
  content: "";
  top: 50%;
  right: -5%;
  width: 110%;
  height: 1px;
  background-color: rgba(178, 169, 174, 1);
}

.out-of-stock-variant:after {
  transform: rotate(151deg);
}

.visually-hidden {
  border: 0;
  clip: rect(0, 0, 0, 0);
  clip: rect(0 0 0 0);
  width: 2px;
  height: 2px;
  margin: -2px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* ==================================Carosal Changes======================== */
.recommended-product > .swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  bottom: -10px !important;
}

.home-product-slider > .swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  bottom: -10px !important;
}

.home-tip-slider > .swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 0.3s opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  bottom: -40px !important;
}

.recommended-product > .swiper-pagination > .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: rgba(1, 64, 50, 1) !important;
}

.home-product-slider > .swiper-pagination > .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #ffffff !important;
}

.home-tip-slider > .swiper-pagination > .swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #ffffff !important;
}

/* .arrow-left {
  left: 0;
}
.arrow-right {
  right: 0;
} */
/* ==================================CSS Changes======================== */

.input-border {
  box-shadow: none;
  border-color: transparent;
}

.account-password-alignment {
  display: flex;
  align-items: center;
}

.show-password {
  position: relative;
  right: 30px;
  cursor: pointer;
}

.register-box-alignment {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.register-showpass-alignment {
  position: relative;
  right: 10px;
  top: -30px;
  cursor: pointer;
}

.signin-pass-alignment {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.signin-show-pass {
  position: relative;
  right: 10px;
  top: -30px;
  cursor: pointer;
}

.signin-pass-err {
  margin-top: -20px;
}

.empty-cart-order {
  margin-top: 10px;
}

.search-modal-input {
  height: auto;
}

.close-search-modal {
  cursor: pointer;
  position: relative;
  top: 33px;
  left: -50px;
}

.close-search-modal-hidden {
  cursor: pointer;
  position: relative;
  top: 33px;
  left: -30px;
}

.search-modal-input {
  box-shadow: none;
}

.calendar-box {
  height: 500px;
  max-width: 560px;
}

.maintenance-page-header {
  font-size: 14px;
}

.santry-sample-main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.santry-sample-h4 {
  font-size: 4rem;
}

.santry-sample-h1 {
  height: 1em;
}

.santry-sample-button {
  padding: 12px;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  background-color: #c73852;
  border-radius: 12px;
  border: none;
}

.maintenance-h2 {
  padding-bottom: 5;
}

.bg-with-height {
  background-color: #fff7f0;
  height: 100vh;
}

.bg-size-60 {
  background-size: 60% auto;
}

.zIndex-9 {
  z-index: 9;
}
.zIndex-99 {
  z-index: 99;
}
.text-colorEight {
  color: #412234;
}

.swiper .swiper-nav svg {
  color: #014032;
  background: #fff;
  position: absolute;
  bottom: -14px;
  border-radius: 50%;
  z-index: 99;
}
.swiper .swiper-nav.swiper-left svg {
  left: calc(50% - 120px);
}
.swiper .swiper-nav.swiper-right svg {
  right: calc(50% - 120px);
}

.slider-image-span {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.slider-image-span img {
  min-height: 100%;
}

#__next > div > div > main > div.mt-\[-130px\].h-full.w-full.overflow-hidden.bg-colorFourteen.pt-44.pb-10 > div.container.mx-auto > div > div.py-10 > div:nth-child(2) {
  padding-top: 10px;
}